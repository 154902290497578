<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="inquiry-wrapper " v-bind:class="{'open-filter-box': filterBoxActive}">
      <div class="add-property-title row align-items-baseline ">
        <div class="col-md-3 col-12 me-auto"> {{ $t('vendor-products-title') }}</div>
        <div class="import-button col-md-3 " @click="$store.dispatch('utils/openModal', 'productImport')">
          <Button :text="$t('automatic-import')" :theme="'outline-grey'" :size="'small'"/>
        </div>
      </div>
      <div class="row labels-order d-none d-md-flex g-0">
        <div class="order-id col-1">{{ $t('vendor-product-id') }}</div>
        <div class="client-name col-4">{{ $t('vendor-products') }}</div>
        <div class="client-phone col-2">{{ $t('vendor-price') }}</div>
        <div class="order-status col-2 ">
          {{ $t('vendor-category') }}
        </div>
        <div class="order-amount col-2"> {{ $t('vendor-visible') }}</div>
      </div>
      <div class="inquiry-item" v-for="product in products">
        <AdminVendorListProduct
            :id="product.id"
            :type="'product-list'"
            :date="product.date"
            :category="product.category"
            :title="product.title"
            :visible="product.status === 1"
            :price="product.price"
            :image="product.mainImage.src"
            @itemDeleted="handleItemDeleted"
        />
      </div>
      <div class="pagination" v-if="products.length > 0">
        <Pagination
            :size="'small'"
            :pages="Math.ceil(totalProducts/limit)"
            :route="'AdminListProducts'"
            @pageChanged="handlePageChange"
        />
      </div>
    </div>
  </div>


  <Modal :name="'productImport'"
         :size="'large'"
         :is-open="$store.state.utils.modals.productImport"
         :title="$t('automated-import-of-products')">
    <div style="width: 420px; max-width: 100%">
      <FileInput @change="e => uploadFile(e)" :file-type="'text/csv'"/>
      <div class="mt-3">
        <div class="d-flex">{{ $t('field-separator') }}:
          <div class="ms-auto">"|"</div>
        </div>
        <div class="d-flex">{{ $t('list-field-separator') }}:
          <div class="ms-auto">";"</div>
        </div>
        <div class="d-flex">{{ $t('example-file') }}:
          <div class="ms-auto"><a href="/static/docs/Sakvartirantite.com-Product_Import_CSV_Example.xlsx"
                                  target="_blank">{{ $t('download-file') }}</a></div>
        </div>
      </div>
    </div>
  </Modal>
</template>
