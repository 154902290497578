import Button from "@/components/Common/Buttons/Button/Button";
import Input from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import AdminVendorListProduct from "@/components/Components/AdminVendorListProduct/AdminVendorListProduct";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import Pagination from "@/components/Components/Pagination/Pagination";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import Modal from "@/components/Modal/Modal";


export default {
    name: 'AdminVendorOrders',
    components: {
        Modal,

        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminVendorListProduct,
        TextArea,
        Dropdown,
        Input,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination
    },
    data() {
        return {
            filterBoxActive: false,
            activeInactiveSwitch: 'active',
            sortingOptions: [
                {
                    title: 'Цена възходяща',
                    value: 'price_ascending',
                },
                {
                    title: 'Цена низходяща',
                    value: 'price_descending',
                },
                {
                    title: 'Дата на добавяне',
                    value: 'create_date',
                },
            ],
            selectedOption: {
                title: 'Цена възходяща',
                value: 'price_ascending',
            },
            products: [],
            totalProducts: null,
            offset: 0,
            limit: 10,
        }
    },
    watch: {
        '$route'() {
            this.loadProducts();
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        async handleItemDeleted(productId) {
            if (confirm("Сигурни ли сте че искате да изтриете този продукт?")) {
                try {
                    await this.$store.dispatch('product/deleteProduct', productId)
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'success', message: 'Продуктът беше изтрит успешно!'
                    });
                } catch (err) {
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'error',
                        message: 'Грешка! Моля, опитайте отново'
                    });
                }
                await this.loadProducts();
            }
        },
        async loadProducts() {
            await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
            const page = this.$route.query.page ?? 1;
            this.offset = page <= 1 ? 0 : (page - 1) * this.limit;
            const result = await this.$store.dispatch("product/getVendorProducts", {
                offset: this.offset,
                limit: this.limit
            });
            this.products = result.data;
            this.totalProducts = result.total;
            this.offset = result.offset;
            await this.$store.dispatch('utils/closeNotificationModal', {type: 'loading'});
        },
        async handlePageChange(page) {
            this.$router.push({path: this.$route.path, query: {page: page + 1}});
        },
        async uploadFile(event) {
            await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
            await this.$store.dispatch('utils/closeModal', 'productImport');
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file, file.name);
            try {
                await this.$store.dispatch('product/importProducts', {file: formData});
                await this.$store.dispatch('utils/closeNotificationModal', {type: 'loading'});
                this.$store.dispatch("utils/openNotificationModal", {
                    message: this.$t('product-import-was-initiated-successfully'),
                    type: 'success'
                });
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error', message: err.message
                });
            }
        },
    },
    async mounted() {
        await this.loadProducts()
    },
}
